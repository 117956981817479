<template>
  <div
    v-if="notifications.length"
    style="position: fixed; right: 0; top: 0; z-index: 1000"
  >
    <v-snackbar
      v-for="item in notifications"
      :key="item.id"
      :class="item.classList || ''"
      class="pr-0"
      :color="item.color"
      :value="item.model"
      :timeout="item.timeout"
      :outlined="item.outlined"
      style="position: unset; height: auto"
      @input="onClose(item, $event)"
    >
      <div class="d-flex justify-center align-center">
        <v-icon
          v-if="item.icon"
          :color="item.iconColor || item.color"
          class="me-3"
          v-text="item.icon"
        />
        {{ item.message }}
        <v-btn
          :color="item.closeColor || 'secondary'"
          text
          icon
          :class="item.closeClassList || ''"
          class="ms-auto"
          @click="onClose(item)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
<!--      <v-progress-linear-->
<!--        v-if="item.progress"-->
<!--        :color="item.color"-->
<!--        :indeterminate="indeterminate"-->
<!--        :value="item.timeout"-->
<!--      ></v-progress-linear>-->
      <template v-if="item.action">
        <div class="text-center mt-2">
          <v-btn
            :color="item.action.color || 'secondary'"
            @click="onActionClick(item)"
          >
            {{ item.action.text }}
          </v-btn>
        </div>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "NotificationMessages",

  // data: () => {
  //   return {
  //     indeterminate: true,
  //   };
  // },

  computed: {
    ...mapGetters(["notifications"]),
  },

  methods: {
    ...mapMutations(["setNotification", "resetNotifications"]),

    onClose(item, value = false) {
      this.setNotification({ id: item.id, value });
    },

    onActionClick(item) {
      console.log("onActionClick: ");
      console.log("this.item: ", item);
      console.log("this.item?.action?.cb: ", item?.action?.cb);
      item?.action?.cb();
    },
  },

  mounted() {
    this.resetNotifications();
  },
};
</script>

<style scoped>
.v-snack__content {
  padding-right: 5px;
}
</style>
