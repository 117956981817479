import { calendarApi } from "@/api";

function initialState() {
  return {
    events: [],
  };
}

export default {
  namespaced: true,

  state: initialState,

  getters: {
    events(state) {
      return state.events;
    },
  },

  mutations: {
    setEvents(state, payload) {
      state.events = payload;
    },

    reset(state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },
  actions: {
    async getEvents({ commit }) {
      let demoTherapist = null;

      // if(!rootGetters["user/me"].therapist && rootGetters["therapist/demoTherapist"]) {
      //     demoTherapist = rootGetters["therapist/demoTherapist"]
      // }

      return calendarApi
        .getEvents({ demoTherapist })
        .then((res) => {
          commit("setEvents", res.data);
        })
        .catch((err) => {
          throw err;
        });
    },

    async getEventsForAdmin() {
      return calendarApi
        .getEventsForAdmin()
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async getEventAndReportForAdmin(context, { eventId }) {
      return calendarApi
        .getEventAndReportForAdmin({ eventId })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async getEventsByUserId({ commit }, { therapistId }) {
      return calendarApi
        .getEventsByUserId({ therapistId })
        .then((res) => {
          commit("setEvents", res.data);
        })
        .catch((err) => {
          throw err;
        });
    },

    async getEventById(context, { id }) {
      return calendarApi
        .getEventById({ id })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async createEvent({ dispatch }, createData) {
      return calendarApi
        .createEvent(createData)
        .then((res) => {
          dispatch("getEvents");
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async updateEvent({ dispatch }, { id, updateData }) {
      return calendarApi
        .updateEvent({ id, updateData })
        .then((res) => {
          dispatch("getEvents");
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async removeEvent({ dispatch }, { id }) {
      return calendarApi
        .removeEvent({ id })
        .then((res) => {
          dispatch("getEvents");
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async bookEvent({ dispatch }, { id }) {
      return calendarApi
        .bookEvent({ id })
        .then((res) => {
          dispatch("getEvents");
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async bookFreeEvent({ dispatch }, { id }) {
      return calendarApi
        .bookFreeEvent({ id })
        .then((res) => {
          dispatch("getEvents");
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async acceptEvent({ dispatch }, { id }) {
      return calendarApi
        .acceptEvent({ id })
        .then((res) => {
          dispatch("getEvents");
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async declineEvent({ dispatch }, { id }) {
      return calendarApi
        .declineEvent({ id })
        .then((res) => {
          dispatch("getEvents");
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async actionFromTherapist({ dispatch }, { id, data }) {
      return calendarApi
        .actionFromTherapist({ id, data })
        .then((res) => {
          dispatch("getEvents");
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async doneEvent(context, { id }) {
      return calendarApi
        .doneEvent({ id })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async cancelEvent(context, { id, data }) {
      return calendarApi
        .cancelEvent({ id, data })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async notJoined(context, { id }) {
      return calendarApi
        .notJoined({ id })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async getNextSession() {
      return calendarApi
        .getNextSession()
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.log("err: ", err.response);
          throw err;
        });
    },

    async changeEventStatus(context, { id, data }) {
      return calendarApi
        .changeEventStatus({ id, data })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async getAgoraToken(context, { id }) {
      return calendarApi
        .getAgoraToken({ id })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.log("err: ", err.response);
          throw err;
        });
    },

    async getTwilioToken(context, { id }) {
      return calendarApi
        .getTwilioToken({ id })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.log("err: ", err.response);
          throw err;
        });
    },

    async getZoomToken(context, { id }) {
      return calendarApi
          .getZoomToken({ id })
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            console.log("err: ", err.response);
            throw err;
          });
    },
  },

  modules: {},
};
