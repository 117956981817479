import serverApi from "../serverApi";

export const promoCodeApi = {
  async getPromoCode({ companyId }) {
    return await serverApi.get(`/promotion-code/company/${companyId}`);
  },

  async checkPromotionCode({ code }) {
    return await serverApi.get(`/promotion-code/check-by-code/${code}`);
  },

  async createPromoCode(createData) {
    return await serverApi.post("/promotion-code", createData);
  },

  async updatePromoCode({ id, updateData }) {
    return await serverApi.patch(`/promotion-code/${id}`, updateData);
  },

  async removePromoCode({ id }) {
    return await serverApi.delete(`/promotion-code/${id}`);
  },
};
