const axios = require("axios");
import router from "../router";
import Store from "../store";
import jwt from 'jsonwebtoken';

let waitingRefresh = false;
// import store from '../store/index';

// console.log("mapState(['authToken']): ", mapState(['authToken']));
// const store = require('../store');

const serverApi = axios.create({
    baseURL: process.env.VUE_APP_SERVER_URL,
});
// serverApi.defaults.baseURL = process.env.VUE_APP_SERVER_URL;

// instance.defaults.headers.common['Authorization'] = store.state.authToken;

// function f() {
//     console.log("store: ", process.env);
// }

// setTimeout(f, 3000)
// TODO: FIX/Beautify
serverApi.interceptors.request.use(
    async function (config) {
        if (waitingRefresh) {
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
        const accessToken = Store.getters['user/accessToken'];
        if (accessToken) {
            const decoded = jwt.decode(accessToken);
            if(decoded && decoded.exp) {
                const expirationDate = new Date(decoded.exp * 1000);
                const now = new Date();

                if(!waitingRefresh && ((expirationDate - now) / 1000 / 60) < 20) {
                    waitingRefresh = true;
                    await Store.dispatch("user/refreshAccessToken")
                }
            }
        }
        return config;
    },
    function (error) {
        // Do something with the request error
        console.error('Request error:', error);

        // Make sure to return the Promise.reject(error) to propagate the error further
        return Promise.reject(error);
    }
);

serverApi.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        if(waitingRefresh) {
            waitingRefresh = false;
        }

        return response;
    },
    async function (error) {
        const originalRequest = error.config;
        if (waitingRefresh) {
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
        if (error.response.status === 401 && !originalRequest._retry) {
            waitingRefresh = true;
            originalRequest._retry = true;
            await Store.dispatch("user/refreshAccessToken");
            waitingRefresh = false;
            serverApi.defaults.headers.common["Authorization"] =
                "Bearer " + Store.state.user.accessToken
            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
            return serverApi(originalRequest);
        }
        // return Promise.reject(error);

        else if (
            error?.response?.status === 401 &&
            router.history.current.name !== "Login"
        ) {
            Store.dispatch("user/logout").then(() => {
                router.replace({name: "Login"});
            });
        }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        return Promise.reject(error);
    }
);

// window.serverApi = serverApi;

export default serverApi;
