import serverApi from "../serverApi";

export const clientFileApi = {
  async getUserClientFile({ userId }) {
    return await serverApi.get(`/client-file/${userId}`);
  },

  async createClientFileMessage({ userId, createData }) {
    return await serverApi.post(`/client-file/${userId}`, createData);
  },

  async updateClientFileMessage({ userId, id, updateData }) {
    return await serverApi.patch(`/client-file/${userId}/${id}`, updateData);
  },

  async removeClientFileMessage({ userId, id }) {
    return await serverApi.delete(`/client-file/${userId}/${id}`);
  },
};
