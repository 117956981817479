/* eslint-disable */
import ZoomVideo from '@zoom/videosdk';

export default class ZoomVideoStream {
  constructor() {
    this.client = ZoomVideo.createClient();
    this.client.init('en-US', 'Global', { patchJsMedia: true })
    this.stream = null;

    this.localVideoTrack = null;
    this.localAudioTrack = null;

    this.videoDevices = [];
    this.audioDevices = [];
    this.allUsers = [];

    this.accessToken = null;
  }

  async leave() {
    await this.client.leave()
  }

  async joinRoom(topic, username) {
    await this.client.join(topic, this.accessToken, username);
    this.stream = this.client.getMediaStream()

    this.allUsers = this.client.getAllUser()

    this.client.on('user-added', (payload) => {
      this.allUsers = this.client.getAllUser()
      console.log("-> this.remoteUsers", this.remoteUsers);
      // if (payload.action === 'Start') {
      //   stream.renderVideo(document.querySelector('#participant-videos-canvas'), payload.userId, 1920, 1080, 0, 0, 3)
      // } else if (payload.action === 'Stop') {
      //   stream.stopRenderVideo(document.querySelector('#participant-videos-canvas'), payload.userId)
      // }
    })

    this.client.on('user-removed', (payload) => {
      this.allUsers = this.client.getAllUser()
      console.log("-> this.remoteUsers", this.remoteUsers);
      // if (payload.action === 'Start') {
      //   stream.renderVideo(document.querySelector('#participant-videos-canvas'), payload.userId, 1920, 1080, 0, 0, 3)
      // } else if (payload.action === 'Stop') {
      //   stream.stopRenderVideo(document.querySelector('#participant-videos-canvas'), payload.userId)
      // }
    })
  }

  async getVideoDevices() {
    if(this.stream) {
      this.videoDevices = this.stream.getCameraList()
      console.log("-> this.stream.getCameraList()", this.stream.getCameraList());
    } else {
     const devices = await ZoomVideo.getDevices()

      this.videoDevices = devices.filter((device) => {
        return device.kind === 'videoinput'
      })

    }
  }


  async getAudioDevices() {
    if(this.stream) {
      this.audioDevices = this.stream.getMicList()
    } else {
      const devices = await ZoomVideo.getDevices()

      this.audioDevices = devices.filter((device) => {
        return device.kind === 'audioinput'
      })

    }
  }

  async setLocalVideoTrack(deviceID) {
    this.localVideoTrack = await ZoomVideo.createLocalVideoTrack(deviceID || this.videoDevices[0].deviceId)
  }

  async setLocalAudioTrack(deviceID) {
    this.localAudioTrack = await ZoomVideo.createLocalAudioTrack(deviceID || this.audioDevices[0].deviceId)
  }

  async getMicrophones() {
    // const deviceInfos = await navigator.mediaDevices.enumerateDevices();
    // return deviceInfos.filter(function (deviceInfo) {
    //   return deviceInfo.kind === "audioinput";
    // });

    // return await AgoraRTC.getMicrophones()
  }

  async setCamera(deviceId) {
    // let detachParent;
    // let oldLocalVideoTrack = this.localVideoTrack;
    //
    // oldLocalVideoTrack.detach().forEach(function (detachedElement) {
    //   detachParent = detachedElement.parentElement;
    //   detachedElement.remove();
    // });
    //
    // this.localVideoTrack = await createLocalVideoTrack({
    //   deviceId: { exact: deviceId },
    // });
    //
    // detachParent?.appendChild(this.localVideoTrack.attach());
    //
    // if (this.room) {
    //   this.room.localParticipant.unpublishTrack(oldLocalVideoTrack);
    //   await this.room.localParticipant.publishTrack(this.localVideoTrack);
    // }

    // .then(function(localVideoTrack) {
    // const tracks = Array.from(localParticipant.videoTracks.values());
    // localParticipant.unpublishTracks(tracks);
    // log(localParticipant.identity   " removed track: "   tracks[0].kind);
    // detachTracks(tracks);
    //
    // localParticipant.publishTrack(localVideoTrack);
    // log(localParticipant.identity   " added track: "   localVideoTrack.kind);
    // const previewContainer = document.getElementById('local-media');
    // attachTracks([localVideoTrack], previewContainer);
    // });
    // await this.localVideoTrack.setDevice(deviceId)
  }

  async setMicrophone(deviceId) {
    // let detachParent;
    // let oldLocalAudioTrack = this.localAudioTrack;
    //
    // this.localAudioTrack.detach().forEach(function (detachedElement) {
    //   detachParent = detachedElement.parentElement;
    //   detachedElement.remove();
    // });
    //
    // this.localAudioTrack = await createLocalAudioTrack({
    //   deviceId: { exact: deviceId },
    // });
    //
    // detachParent?.appendChild(this.localAudioTrack.attach());
    //
    // if (this.room) {
    //   this.room.localParticipant.unpublishTrack(oldLocalAudioTrack);
    //   await this.room.localParticipant.publishTrack(this.localAudioTrack);
    // }
  }

  async getOrCreateTracks() {
    // if (!this.localAudioTrack || !this.localVideoTrack) {
    //   const microphoneTrack = await createLocalAudioTrack();
    //   const cameraTrack = await createLocalVideoTrack();
    //   // const [microphoneTrack, cameraTrack] = await AgoraRTC.createMicrophoneAndCameraTracks();
    //
    //   console.log("getOrCreateTracks: ");
    //   console.log("microphoneTrack: ", microphoneTrack);
    //   console.log("cameraTrack: ", cameraTrack);
    //
    //   this.localAudioTrack = microphoneTrack;
    //   this.localVideoTrack = cameraTrack;
    //
    // }
  }

  async leaveChanel() {

    // await this.removeVideoStream();
    //
    // if (this.room) {
    //   await this.room.disconnect();
    // }

  }

  removeVideoStream() {
    // this.localAudioTrack?.detach();
    // this.localAudioTrack?.stop();
    // this.localVideoTrack?.detach();
    // this.localVideoTrack?.stop();
    //
    // this.localAudioTrack = null;
    // this.localVideoTrack = null;
    // this.room = null;
    // this.participants = null;
    // this._updated = false;
  }

  install(Vue) {

    window.ZoomVideoStream = this;
    Vue.prototype.$ZoomVideoStream = this;
  }
}
