import createPersistedState from "vuex-persistedstate";
import * as shvl from "shvl";

export default [
  createPersistedState({
    key: "talktime-app",
    paths: ["user", "app"],
    reducer(state, paths) {
      if (!state.user.accessToken) {
        return ["app.lang", "app.themeDark"].reduce(function (substate, path) {
          return shvl.set(substate, path, shvl.get(state, path));
        }, {});
      }

      return Array.isArray(paths)
        ? paths.reduce(function (substate, path) {
            return shvl.set(substate, path, shvl.get(state, path));
          }, {})
        : state;
    },
  }),
];
