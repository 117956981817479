function initialState() {
  return {
    globalLoading: false,
    notifications: [],
    lang: "en",
    themeDark: false,
  };
}

export default {
  namespaced: false,

  state: initialState,

  getters: {
    notifications(state) {
      return state.notifications || [];
    },

    globalLoading(state) {
      return state.globalLoading;
    },

    lang(state) {
      return state.lang;
    },

    themeDark(state) {
      return state.themeDark;
    },
  },

  mutations: {
    addNotification(state, payload) {
      payload.timeout =
        (payload.timeout || 3000) + 2000 * state.notifications.length;


      let id = "_" + Math.random().toString(36).substr(2, 9)
      let exist = state.notifications.find(e => e.notificationType === payload?.notificationType);
      if(exist) {
        for (let k of Object.keys(payload)) {
          exist[k] = payload[k]
        }
      } else {
        state.notifications.push({
          id: id,
          notificationType: payload?.notificationType || id,
          model: true,
          icon: "mdi-information-outline",
          closeColor: "secondary",
          message: "",
          outlined: false,
          color: undefined,
          ...payload,
        });
      }

    },

    setNotification(state, { value, id }) {
      let index = state.notifications.findIndex((e) => e.id === id);
      state.notifications[index] = !value;
      if (value === false) {
        state.notifications.splice(index, 1);
      }
    },

    setGlobalLoading(state, payload) {
      state.globalLoading = payload;
    },

    changeLang(state, payload) {
      state.lang = payload;
    },

    changeThemeDark(state, payload) {
      state.themeDark = payload;
    },

    resetNotifications(state) {
      state.notifications = [];
    },

    reset(state) {
      state.globalLoading = false;
      state.notifications = [];
      // const s = initialState();
      // Object.keys(s).forEach((key) => {
      //   state[key] = s[key];
      // });
    },
  },
};
