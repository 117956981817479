import serverApi from "../serverApi";

export const therapistApi = {

  async getAllTherapists() {
    return await serverApi.get("/therapist");
  },

  async getTherapistForSelection() {
    return await serverApi.get("/therapist/selection");
  },

  async changeTherapistForUser(changeTherapistData) {
    return await serverApi.post(
      "/therapist/change-therapist",
      changeTherapistData
    );
  },


  async createTherapist(createTherapistData) {
    return await serverApi.post(
        "/therapist/register",
        createTherapistData
    );
  },


  async addTherapistToUserCompany(changeTherapistData) {
    return await serverApi.post(
      "/therapist/add-therapist-to-user",
      changeTherapistData
    );
  },

  async getClientsAndSubscriptions({ id = "" }) {
    return await serverApi.get(`/therapist/clients-subscriptions/${id}`);
  },
};
