import { journalApi } from "@/api";

function initialState() {
  return {};
}

export default {
  namespaced: true,

  state: initialState,

  getters: {},

  mutations: {
    // reset(state) {
    //     const s = initialState();
    //     Object.keys(s).forEach((key) => {
    //         state[key] = s[key];
    //     });
    // },
  },
  actions: {
    async getMyJournal() {
      return journalApi
        .getMyJournal()
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async getUserJournal(context, { id }) {
      return journalApi
        .getUserJournal({ id })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async createJournalMessage(context, { createData }) {
      return journalApi
        .createJournalMessage({ createData })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async updateJournalMessage(context, { id, updateData }) {
      return journalApi
        .updateJournalMessage({ id, updateData })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async removeJournalMessage(context, { id }) {
      return journalApi
        .removeJournalMessage({ id })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async setShared(context, { id, setSharedData }) {
      return journalApi
        .setShared({ id, setSharedData })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },
  },

  modules: {},
};
