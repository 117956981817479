import serverApi from "../serverApi";

export const corporatePlanApi = {
  async getCorporatePlans() {
    return await serverApi.get(`/corporate-plan`);
  },

  async getCorporatePlan({ id }) {
    return await serverApi.get(`/corporate-plan/${id}`);
  },

  async createCorporatePlan(createData) {
    return await serverApi.post("/corporate-plan", createData);
  },

  async updateCorporatePlan({ id, updateData }) {
    return await serverApi.patch(`/corporate-plan/${id}`, updateData);
  },
};
