import { therapistTierApi } from "@/api";


function initialState() {
  return {
    tiers: [],
  };
}


export default {
  namespaced: true,

  state: initialState,


  getters: {
    tiers(state) {
      return state.tiers
    }
  },

  mutations: {
    setTiers(state, payload) {
      state.tiers = payload
    },

    reset(state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },

  actions: {
    async getTiers({commit}) {
      return therapistTierApi
        .getTiers()
        .then((res) => {
          commit('setTiers', res.data);
          return res.data;
        })
        .catch((err) => {
          throw err;
        })
    },
  },
};
