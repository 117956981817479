const { io } = require("socket.io-client");

export default class VueSocket {
  constructor({ url }) {
    this.socket = io(url, { transports: ["websocket"] });
    this._connectionCallbacks = [];

    this.socket.on("connect", () => {
      // this.runConnectionCallbacks(socket)

      this.runConnectionCallbacks();
      this.socket.emit("user-connected");
    });

    this.socket.on("disconnect", (data) => {
      console.log("disconnect: ", this.socket.id); // undefined
      console.log("data: ", data);
    });

    this.socket.on("error", (err) => {
      console.log("error: ", err); // undefined
    });

    // this.socket.onAny((eventName, ...args) => {
    //     console.log("eventName: ", eventName);
    //     console.log("args: ", args);
    // });
  }

  runConnectionCallbacks() {
    this._connectionCallbacks.forEach((e) => e.cb());
  }

  addConnectionCallback({ id, cb }) {
    if (typeof cb === "function") {
      this._connectionCallbacks.push({ id, cb });
    }
  }
  removeConnectionCallback(id) {
    let index = this._connectionCallbacks.findIndex((e) => e.id === id);
    if (index !== -1) {
      this._connectionCallbacks.splice(index, 1);
    }
  }

  install(Vue) {
    // window.socket = this.socket

    // 1. добавление глобального метода или свойства
    // Vue.myGlobalMethod = function () {
    //     // некоторая логика ...
    // }

    // // 2. добавление глобального объекта
    // Vue.directive('my-directive', {
    //     bind(el, binding, vnode, oldVnode) {
    //         // некоторая логика ...
    //     }
    // })
    //
    // // 3. добавление опций компонентов
    // Vue.mixin({
    //     created: function () {
    //
    //         // некоторая логика ...
    //     }
    // })

    Vue.prototype.$socket = this.socket;
    Vue.prototype.$socketService = this;
  }
}
