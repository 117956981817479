import Vue from "vue";
import VueRouter from "vue-router";
import Store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "Calendar" },
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "NoUser" */ "../views/loggedIn/Base"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: "BaseContent",
        redirect: { name: "Calendar" },
        component: () =>
          import(
            /* webpackChunkName: "NoUser" */ "../views/loggedIn/BaseContent"
          ),
        children: [
          {
            path: "chat",
            name: "Chat",
            component: () =>
              import(
                /* webpackChunkName: "Chat" */ "../views/loggedIn/Layout/Chat"
              ),
            children: [
              {
                path: "room/:id",
                name: "Room",
                component: () =>
                  import(
                    /* webpackChunkName: "Chat" */ "../views/loggedIn/Layout/Room"
                  ),
              },
            ],
          },
          // {
          //   path: "zoom-test",
          //   name: "ZoomTest",
          //   component: () =>
          //       import(
          //           /* webpackChunkName: "VideoCall" */ "../views/loggedIn/VideoCall/ZoomTest"
          //           ),
          // },
          {
            path: "video-call",
            name: "VideoCallBase",
            component: () =>
              import(
                /* webpackChunkName: "VideoCall" */ "../views/loggedIn/VideoCall/VideoCallBase"
              ),
          },
          {
            path: "next-session-suggestion",
            name: "NextSessionSuggestion",
            component: () =>
              import(
                /* webpackChunkName: "VideoCall" */ "../views/loggedIn/VideoCall/NextSessionSuggestion"
              ),
          },
          {
            path: "video-call/:id",
            name: "VideoCallRoom",
            component: () =>
              import(
                /* webpackChunkName: "VideoCall" */ "../views/loggedIn/VideoCall/VideoCall"
              ),
          },
          {
            path: "patients",
            name: "Patients",
            component: () =>
              import(
                /* webpackChunkName: "Patients" */ "../views/loggedIn/Patients/Patients"
              ),
          },
          {
            path: "client-file/:userId",
            name: "ClientFile",
            component: () =>
              import(
                /* webpackChunkName: "ClientFile" */ "../views/loggedIn/Patients/ClientFile"
              ),
          },
          {
            path: "interactive-journal/:userId",
            name: "InteractiveJournal",
            component: () =>
              import(
                /* webpackChunkName: "InteractiveJournal" */ "../views/loggedIn/Therapist/InteractiveJournal"
              ),
          },
          {
            path: "patient/:id",
            name: "PatientProfile",
            component: () =>
              import(
                /* webpackChunkName: "PatientProfile" */ "../views/loggedIn/Patients/PatientProfile"
              ),
          },
          {
            path: "/calendar",
            name: "Calendar",
            component: () =>
              import(
                /* webpackChunkName: "Calendar" */ "../views/loggedIn/Layout/Calendar"
              ),
          },
          {
            path: "/profile",
            name: "Profile",
            component: () =>
              import(
                /* webpackChunkName: "Profile" */ "../views/loggedIn/Profile/Profile"
              ),
          },
          {
            path: "/my-plan",
            name: "MyPlan",
            component: () =>
              import(
                /* webpackChunkName: "Profile" */ "../views/loggedIn/Profile/MyPlan"
              ),
          },
          {
            path: "/my-therapist",
            name: "MyTherapist",
            component: () =>
              import(
                /* webpackChunkName: "Profile" */ "../views/loggedIn/Profile/MyTherapist"
              ),
          },
          {
            path: "/change-therapist",
            name: "ChangeTherapist",
            component: () =>
              import(
                /* webpackChunkName: "Profile" */ "../views/loggedIn/Profile/ChangeTherapist"
              ),
          },
          // {
          //   path: "/change-initial-assessment",
          //   name: "ChangeInitialAssessment",
          //   component: () =>
          //     import(
          //       /* webpackChunkName: "Profile" */ "../views/loggedIn/Profile/ChangeInitialAssessment"
          //     ),
          // },
          {
            path: "/admin",
            name: "Admin",
            meta: { requiresAdmin: true },
            component: () =>
              import(
                /* webpackChunkName: "Admin" */ "../views/loggedIn/Admin/BaseAdmin"
              ),
            children: [
              {
                path: "data-export",
                name: "DataExport",
                component: () =>
                  import(
                    /* webpackChunkName: "Admin" */ "../views/loggedIn/Admin/DataExport"
                  ),
              },
              // {
              //     path: 'admin-calendar',
              //     name: 'AdminCalendar',
              //     component: () =>
              //         import(
              //             /* webpackChunkName: "Admin" */ '../views/loggedIn/Admin/AdminCalendar'
              //             ),
              // },
              {
                path: "session-schedule",
                name: "SessionSchedule",
                component: () =>
                  import(
                    /* webpackChunkName: "Admin" */ "../views/loggedIn/Admin/SessionSchedule"
                  ),
              },
              {
                path: "therapists-panel",
                name: "TherapistsPanel",
                component: () =>
                  import(
                    /* webpackChunkName: "Admin" */ "../views/loggedIn/Admin/TherapistsPanel"
                  ),
              },
              {
                path: "client-log",
                name: "ClientLog",
                component: () =>
                  import(
                    /* webpackChunkName: "Admin" */ "../views/loggedIn/Admin/ClientLogV2"
                  ),
              },
              {
                path: "users",
                name: "Users",
                component: () =>
                  import(
                    /* webpackChunkName: "Admin" */ "../views/loggedIn/Admin/Users"
                  ),
              },
              {
                path: "access-users",
                name: "AccessUsers",
                component: () =>
                  import(
                    /* webpackChunkName: "Admin" */ "../views/loggedIn/Admin/AccessUsers"
                  ),
              },
              {
                path: "companies",
                name: "Companies",
                component: () =>
                  import(
                    /* webpackChunkName: "Admin" */ "../views/loggedIn/Admin/CorporateProgram/Companies"
                  ),
              },
              {
                path: "client-database",
                name: "ClientDatabase",
                component: () =>
                  import(
                    /* webpackChunkName: "Admin" */ "../views/loggedIn/Admin/CorporateProgram/ClientDatabase.vue"
                  ),
              },
              {
                path: "companies/:companyId/promo-codes",
                name: "CompanyPromoCodes",
                component: () =>
                  import(
                    /* webpackChunkName: "Admin" */ "../views/loggedIn/Admin/CorporateProgram/PromoCodes"
                  ),
              },
              {
                path: "corporate-plan",
                name: "CorporatePlan",
                component: () =>
                  import(
                    /* webpackChunkName: "Admin" */ "../views/loggedIn/Admin/CorporateProgram/CoproratePlan"
                  ),
              },
            ],
          },
        ],
      },
      {
        path: "/therapist-selection",
        name: "TherapistSelection",
        component: () =>
          import(
            /* webpackChunkName: "TherapistSelection" */ "../views/loggedIn/Alone/TherapistSelection"
          ),
      },
      {
        path: "/terminate-access",
        name: "TerminateAccess",
        component: () =>
          import(
            /* webpackChunkName: "NoUser" */ "../views/noUser/InitialAssessment/TerminateAccess"
          ),
      },

      {
        path: "/pricing",
        name: "Pricing",
        component: () =>
          import(
            /* webpackChunkName: "Pricing" */ "../views/loggedIn/Alone/TherapistSelectionNextSteps"
          ),
      },
      {
        path: "/retake-fit-assessment",
        name: "FitAssessmentQuestionsRetake",
        component: () =>
          import(
            /* webpackChunkName: "Pricing" */ "../views/loggedIn/Alone/Retake/FitAssessmentQuestionsRetake"
          ),
      },
      {
        path: "/retake-primary-reasons",
        name: "PrimaryReasonsRetake",
        component: () =>
          import(
            /* webpackChunkName: "Pricing" */ "../views/loggedIn/Alone/Retake/PrimaryReasonsRetake"
          ),
      },
      {
        path: "/retake-teen-therapy-reasons",
        name: "TeenTherapyReasonsRetake",
        component: () =>
          import(
            /* webpackChunkName: "Pricing" */ "../views/loggedIn/Alone/Retake/TeenTherapyReasonsRetake"
          ),
      },
      // {
      //   path: "/pricing",
      //   name: "Pricing",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "Pricing" */ "../views/loggedIn/Alone/Pricing"
      //     ),
      // },
      // {
      //   path: "/fit-assessment",
      //   name: "FitAssessment",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "NoUser" */ "../views/loggedIn/Alone/FitAssessment"
      //     ),
      // },

      {
        path: "/retake-initial-assessment",
        name: "RetakeInitialAssessment",
        component: () =>
          import(
            /* webpackChunkName: "Pricing" */ "../views/loggedIn/Alone/RetakeInitialAssessment"
          ),
      },
      {
        path: "/welcome-back",
        name: "WelcomeBack",
        component: () =>
          import(
            /* webpackChunkName: "WelcomeBack" */ "../views/loggedIn/WelcomeBack"
          ),
      },
      {
        path: "/submitted-by-client",
        name: "SubmittedByClient",
        component: () =>
          import(
            /* webpackChunkName: "SubmittedByClient" */ "../views/loggedIn/Insurance/SubmittedByClient"
          ),
      },
      {
        path: "/confirmed",
        name: "ConfirmedInsurance",
        component: () =>
          import(
            /* webpackChunkName: "Confirmed" */ "../views/loggedIn/Insurance/Confirmed"
          ),
      },
      {
        path: "/not-confirmed",
        name: "NotConfirmedInsurance",
        component: () =>
          import(
            /* webpackChunkName: "NotConfirmed" */ "../views/loggedIn/Insurance/NotConfirmed"
          ),
      },
      {
        path: "/insurance-form",
        name: "InsuranceFormInner",
        component: () =>
          import(
            /* webpackChunkName: "NotConfirmed" */ "../views/loggedIn/Insurance/InsuranceFormInner"
            ),
      },
      {
        path: "/verify-email",
        name: "VerifyEmail",
        component: () =>
          import(
            /* webpackChunkName: "NoUser" */ "../views/loggedIn/Alone/VerifyEmailAddress"
          ),
        props: true,
      },
      // {
      //   path: "/use-corporate-coupon",
      //   name: "useCorporateCoupon",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "useCorporateCoupon" */ "../views/noUser/CorporateProgram/AccessTypeLoggedIn"
      //     ),
      // },
    ],
  },

  {
    path: "",
    component: () =>
      import(/* webpackChunkName: "NoUser" */ "../views/noUser/Base"),
    children: [
      {
        path: "/login",
        name: "Login",
        component: () =>
          import(/* webpackChunkName: "NoUser" */ "../views/noUser/Login"),
      },
      {
        path: "/verify/:token",
        name: "Verify",
        component: () =>
          import(/* webpackChunkName: "NoUser" */ "../views/noUser/Verify"),
      },
      {
        path: "/check-email",
        name: "CheckEmail",
        component: () =>
          import(/* webpackChunkName: "NoUser" */ "../views/noUser/CheckEmail"),
        props: true,
      },
      {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: () =>
          import(
            /* webpackChunkName: "NoUser" */ "../views/noUser/ForgotPassword"
          ),
        props: true,
      },
      {
        path: "/reset-password/:token",
        name: "ResetPassword",
        component: () =>
          import(
            /* webpackChunkName: "NoUser" */ "../views/noUser/ResetPassword"
          ),
      },
      {
        path: "/initial-assessment",
        redirect: { name: "Services" },
        name: "InitialAssessment",
        component: () =>
          import(
            /* webpackChunkName: "NoUser" */ "../views/noUser/InitialAssessment/Base"
          ),
        props: true,
      },
      {
        path: "/services",
        name: "Services",
        component: () =>
          import(/* webpackChunkName: "NoUser" */ "../views/noUser/Sponser"),
      },
      {
        path: "/types",
        name: "Types",
        component: () =>
          import(/* webpackChunkName: "NoUser" */ "../views/noUser/Services"),
      },
      {
        path: "/corporate-pricing",
        name: "CorporatePricing",
        component: () =>
          import(
            /* webpackChunkName: "NoUser" */ "../views/noUser/CorporateProgram/CorporatePricing"
          ),
      },
      // {
      //   path: "/corporate-services",
      //   name: "CorporateServices",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "NoUser" */ "../views/noUser/CorporateProgram/CorporateServices"
      //     ),
      // },
      {
        path: "/access-type",
        name: "AccessType",
        component: () =>
          import(
            /* webpackChunkName: "NoUser" */ "../views/noUser/CorporateProgram/AccessType"
          ),
      },
      {
        path: "/primary-reasons",
        name: "PrimaryReasons",
        component: () =>
          import(
            /* webpackChunkName: "NoUser" */ "../views/noUser/PrimaryReasons"
          ),
      },
      {
        path: "/register",
        name: "Register",
        component: () =>
          import(/* webpackChunkName: "NoUser" */ "../views/noUser/Register"),
      },
      {
        path: "/fit-assessment-questions",
        name: "FitAssessmentQuestions",
        component: () =>
          import(
            /* webpackChunkName: "NoUser" */ "../views/noUser/FitAssessmentQuestions"
          ),
      },
      {
        path: "/teen-therapy-reasons",
        name: "TeenTherapyReasons",
        component: () =>
          import(
            /* webpackChunkName: "NoUser" */ "../views/noUser/teenTherapy/TeenTherapyReasons"
          ),
      },
      {
        path: "/teen-therapy-not-parent",
        name: "TeenTherapyNotChildParent",
        component: () =>
          import(
            /* webpackChunkName: "NoUser" */ "../views/noUser/teenTherapy/TeenTherapyNotChildParent"
          ),
      },
      {
        path: "/teen-therapy-below",
        name: "TeenTherapyBelow",
        component: () =>
          import(
            /* webpackChunkName: "NoUser" */ "../views/noUser/teenTherapy/TeenTherapyBelow"
          ),
      },
      {
        path: "/teen-therapy-older",
        name: "TeenTherapyOlder",
        component: () =>
          import(
            /* webpackChunkName: "NoUser" */ "../views/noUser/teenTherapy/TeenTherapyOlder"
          ),
      },
      {
        path: "/teen-therapy-details",
        name: "TeenTherapyDetails",
        component: () =>
          import(
            /* webpackChunkName: "NoUser" */ "../views/noUser/teenTherapy/TeenTherapyDetails"
          ),
      },
    ],
  },
  {
    path: "*",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "NoUser" */ "../components/404"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  let lang = to.query?.["lang"]?.toLowerCase();

  if (["en", "ar"].includes(lang)) {
    Store.commit("changeLang", lang);
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!Store.getters["user/isLoggedIn"]) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
