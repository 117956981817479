import { clientSurveyApi } from "@/api";

function initialState() {
  return {
    clientSurveys: [],
  };
}

export default {
  namespaced: true,

  state: initialState,

  getters: {
    clientSurveys(state) {
      return state.clientSurveys;
    },
  },

  mutations: {
    setClientSurveys(state, payload) {
      state.clientSurveys = payload;
    },

    reset(state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },

  actions: {
    async getPendingClientSurvey({ commit }) {
      return clientSurveyApi
        .getPendingClientSurvey()
        .then((res) => {
          commit("setClientSurveys", res.data);
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async makeDone(context, { id, data }) {
      return clientSurveyApi
        .makeDone({ id, data })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async tyrLater(context, { id }) {
      return clientSurveyApi
        .tryLater({ id })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },
  },

  modules: {},
};
