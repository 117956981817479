import serverApi from "../serverApi";

export const companyApi = {
  async getCompanies() {
    return await serverApi.get(`/company`);
  },

  async getCompany({ id }) {
    return await serverApi.get(`/company/${id}`);
  },

  async createCompany(createData) {
    return await serverApi.post("/company", createData);
  },

  async updateCompany({ id, updateData }) {
    return await serverApi.patch(`/company/${id}`, updateData);
  },

  async removeCompany({ id }) {
    return await serverApi.delete(`/calendar/${id}`);
  },

  async getInsuranceCompanies() {
    return await serverApi.get(`/company/insurance-companies`);
  },
};
