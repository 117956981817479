import serverApi from "../serverApi";

export const calendarApi = {
  async getEvents({ demoTherapist }) {
    return await serverApi.get(
      `/calendar${demoTherapist ? "?therapist-demo=" + demoTherapist._id : ""}`
    );
  },

  async getEventsForAdmin() {
    return await serverApi.get("/calendar/therapist-events");
  },

  async getEventAndReportForAdmin({ eventId }) {
    return await serverApi.get(`/calendar/therapist-events/${eventId}`);
  },

  async getEventsByUserId({ therapistId }) {
    return await serverApi.get(`/calendar/therapist/${therapistId}`);
  },

  async getEventById({ id }) {
    return await serverApi.get(`/calendar/event/${id}`);
  },

  async createEvent(createData) {
    return await serverApi.post("/calendar", createData);
  },

  async updateEvent({ id, updateData }) {
    return await serverApi.patch(`/calendar/${id}`, updateData);
  },

  async removeEvent({ id }) {
    return await serverApi.delete(`/calendar/${id}`);
  },

  async bookEvent({ id }) {
    return await serverApi.patch(`/calendar/book/${id}`);
  },

  async bookFreeEvent({ id }) {
    return await serverApi.patch(`/calendar/book-free/${id}`);
  },

  async acceptEvent({ id }) {
    return await serverApi.patch(`/calendar/accept/${id}`);
  },

  async declineEvent({ id }) {
    return await serverApi.patch(`/calendar/decline/${id}`);
  },

  async actionFromTherapist({ id, data }) {
    return await serverApi.patch(`/calendar/refund-from-therapist/${id}`, data);
  },

  async cancelEvent({ id, data }) {
    return await serverApi.patch(`/calendar/cancel/${id}`, data);
  },

  async doneEvent({ id }) {
    return await serverApi.patch(`/calendar/done/${id}`);
  },

  async notJoined({ id }) {
    return await serverApi.patch(`/calendar/not-joined/${id}`);
  },

  async getNextSession() {
    return await serverApi.get("/calendar/next-session");
  },

  async changeEventStatus({ id, data }) {
    return await serverApi.patch(`/calendar/change-status/${id}`, data);
  },

  async getAgoraToken({ id }) {
    return await serverApi.get(`/calendar/get-agora-token/${id}`);
  },

  async getTwilioToken({ id }) {
    return await serverApi.get(`/calendar/get-twilio-token/${id}`);
  },

  async getZoomToken({ id }) {
    return await serverApi.get(`/calendar/get-zoom-token/${id}`);
  },
};
