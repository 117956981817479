import { clientFileApi } from "@/api";

function initialState() {
  return {};
}

export default {
  namespaced: true,

  state: initialState,

  getters: {},

  mutations: {
    // reset(state) {
    //     const s = initialState();
    //     Object.keys(s).forEach((key) => {
    //         state[key] = s[key];
    //     });
    // },
  },
  actions: {
    async getUserClientFile(context, { userId }) {
      return clientFileApi
        .getUserClientFile({ userId })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async createClientFileMessage(context, { userId, createData }) {
      return clientFileApi
        .createClientFileMessage({ userId, createData })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async updateClientFileMessage(context, { userId, id, updateData }) {
      return clientFileApi
        .updateClientFileMessage({ userId, id, updateData })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async removeClientFileMessage(context, { userId, id }) {
      return clientFileApi
        .removeClientFileMessage({ userId, id })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },
  },

  modules: {},
};
