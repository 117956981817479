import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

// import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },

    dark: false,

    themes: {
      light: {
        primary: "#3475B2",
        indigo: "#4b0082",
        secondary: "#00CEBE",
        black: "#000",
        white: "#fff",
        warning: "#F8AF2F",
        muted: "#C4C4C4",
        danger: "#FB6666",
        success: "#02CFBF",
        darkBlue: "#001424",
        lightGrey: "#fbfbfb",
      },
      // dark: {
      //   dark: "#eeeeee",
      //   primary: "#ccc",
      //   indigo: "#4b0082",
      //   secondary: "#00CEBE",
      //   black: "#fff",
      //   white: "#272727",
      //   muted: "#C4C4C4",
      //   danger: "#FB6666",
      //   success: "#02CFBF",
      //   darkBlue: "#001424",
      //   lightGrey: "#363636",
      // },
    },
  },
});

export default vuetify;
