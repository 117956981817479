import { companyApi, promoCodeApi } from "@/api";

function initialState() {
  return {};
}

export default {
  namespaced: true,

  state: initialState,

  getters: {},

  mutations: {
    // reset(state) {
    //     const s = initialState();
    //     Object.keys(s).forEach((key) => {
    //         state[key] = s[key];
    //     });
    // },
  },
  actions: {
    async getCompanies() {
      return companyApi
        .getCompanies()
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async getInsuranceCompanies() {
      return companyApi
        .getInsuranceCompanies()
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async getCompany(context, { id }) {
      return companyApi
        .getCompany({ id })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async createCompany(context, { createData }) {
      return companyApi
        .createCompany(createData)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async updateCompany(context, { id, updateData }) {
      return companyApi
        .updateCompany({ id, updateData })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async removeCompany(context, { id }) {
      return companyApi
        .removeCompany({ id })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async getPromoCode(context, { companyId }) {
      return promoCodeApi
        .getPromoCode({ companyId })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async checkPromotionCode(context, { code }) {
      return promoCodeApi
        .checkPromotionCode({ code })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async createPromoCode(context, { createData }) {
      return promoCodeApi
        .createPromoCode(createData)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async updatePromoCode(context, { id, updateData }) {
      return promoCodeApi
        .updatePromoCode({ id, updateData })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async removePromoCode(context, { id }) {
      return promoCodeApi
        .removePromoCode({ id })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },
  },

  modules: {},
};
