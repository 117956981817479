import serverApi from "../serverApi";

export const subscriptionApi = {
  async getPrices() {
    return await serverApi.get("/subscription/prices");
  },

  async subscribe(subscribeData) {
    return await serverApi.post("/subscription/subscribe", subscribeData);
  },

  async cancelSubscriptionEndOfPeriod() {
    return await serverApi.post(
      "/subscription/cancel-subscription-end-of-period"
    );
  },

  async createLiveSession(numberOfLiveSessions) {
    return await serverApi.post(
      "/subscription/create-live-session",
      numberOfLiveSessions
    );
  },
};
