function initialState() {
  return {
    promoCodeData: null,
    insuranceData: null,
    sponserType: "",
    therapyType: "",
    userDisorder: {
      default: [],
      other: "",
    },
    clientPrefer: {
      therapistGender: "",
      therapistLanguage: "",
    },
  };
}

export default {
  namespaced: true,
  
  state: initialState,
  getters: {
    getSponserType(state) {
      return state.sponserType
    }
  },

  mutations: {
    setTherapyType(state, payload) {
      state.therapyType = payload;
    },
    setSponserType(state, payload) {
      state.sponserType = payload
    },

    setPromoCodeData(state, payload) {
      state.promoCodeData = payload;
    },

    setInsuranceData(state, payload) {
      state.insuranceData = payload;
      state.insuranceData.status = 'submitted'
    },

    setUserDisorder(state, payload) {
      state.userDisorder = payload;
    },

    setClientPrefer(state, payload) {
      state.clientPrefer = payload;
    },

    reset(state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },
};
