import serverApi from "../serverApi";

export const clientSurveyApi = {
  // async getMonthlyReport({date}) {
  //     return await serverApi.get(`/report/get-monthly-report/?date=${date}`);
  // },

  async getPendingClientSurvey() {
    return await serverApi.get(`/client-survey/pending`);
  },

  async makeDone({ id, data }) {
    return await serverApi.put(`/client-survey/make-done/${id}`, data);
  },

  async tryLater({ id }) {
    return await serverApi.put(`/client-survey/try-later/${id}`);
  },
};
