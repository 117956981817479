import { disorderApi } from "@/api";

function initialState() {
  return {
    disorders: [],
  };
}

export default {
  namespaced: true,

  state: initialState,

  getters: {
    disorders(state) {
      return state.disorders;
    },
  },

  mutations: {
    setDisorders(state, payload) {
      state.disorders = payload;
    },
  },

  actions: {
    async getAll({ commit }) {
      return disorderApi
        .getAll()
        .then((res) => {
          commit("setDisorders", res.data);
        })
        .catch((err) => {
          throw err;
        });
    },
  },

  modules: {},
};
