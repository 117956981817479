<template>
  <v-app>
    <notification-messages />
    <v-alert
      color="secondary"
      class="mb-0 not-support"
      rounded="0"
      dismissible
      v-show="browserSupport"
    >
      <p class="white--text text-center title">
        {{ $t("It looks like you’re using a browser we don’t fully support.") }}
      </p>
      <p class="white--text text-center body-2">
        {{ $t("We recommend to try with the latest version of") }}
        <a
          href="https://www.google.ru/intl/en/chrome/thank-you.html?brand=IXYC&statcb=0&installdataindex=empty&defaultbrowser=0#"
          class="primary--text font-weight-bold"
          >Google Chrome</a
        >
        {{ $t("or") }}
        <a
          href="https://www.mozilla.org/ru/firefox/download/thanks/"
          class="primary--text font-weight-bold"
          >Firefox</a
        >
      </p>
    </v-alert>
    <v-main>
      <router-view />
    </v-main>

  </v-app>
</template>

<script>
/* eslint-disable */
import serverApi from "./api/serverApi";
import NotificationMessages from "@/components/NotificationMessages";
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";

export default {
  name: "App",

  components: {
    NotificationMessages,
  },

  data: () => {
    return {
      videoSupportCheck: true,
    };
  },

  computed: {
    ...mapGetters(["themeDark", "lang"]),

    browserSupport() {
      return (
        !this.videoSupportCheck ||
        (this.$browserDetect.meta.name !== "Chrome" &&
          this.$browserDetect.meta.name !== "Firefox" &&
          this.$browserDetect.meta.name !== "Safari")
      );
    },
  },

  async created() {
    // this.videoSupportCheck = this.$AgoraVideoStream.checkSystemRequirements();
    this.videoSupportCheck = this.$TwilioVideoStream.checkSystemRequirements();
    // console.log("this.$TwilioVideoStream.checkSystemRequirements(): ", );

    //set access token in header for request
    serverApi.defaults.headers.common["Authorization"] =
      "Bearer " + this.$store.state.user.accessToken;

    this.$socket.auth = { token: this.$store.state.user.accessToken };

    this.$store.watch(
      (state) => state.user.accessToken,
      (value) => {
        if (!value) {
          this.$router.push({ name: "Login" });
        }
        //watch accessToken changes and set in header for request
        serverApi.defaults.headers.common["Authorization"] = "Bearer " + value;
        this.$socket.auth = { token: value };
        this.$socket.disconnect();
        this.$socket.connect();
      }
    );

    this.$store.watch(
      (state) => state.app.themeDark,
      (value) => {
        this.$vuetify.theme.dark = value;
      }
    );

    this.$store.watch(
      (state) => state.app.lang,
      (value) => {
        if (this?.$route?.query?.lang) {
          this.$router.replace({ query: null });
        }

        if (
          this.$store.getters["user/me"]?.email &&
          this.$store.getters["user/me"]?.lang !== value
        ) {
          this.$store.dispatch("user/updateMeLang", { lang: value });
        }

        this.$i18n.locale = value;
        this.$vuetify.rtl = value === "ar";
        moment.locale(value);
        this.$router.go(0);
      }
    );

    this.$vuetify.theme.dark = this.themeDark;
    this.$vuetify.rtl = this.lang === "ar";
    this.$i18n.locale = this.lang;
    moment.locale(this.lang);

    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    // We listen to the resize event
    window.addEventListener("resize", () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });

    // let browser = await this.$browserDetect.meta.name;
    // console.log(browser, "browser")
    // if (browser != "Chrome" && browser != "Safari" && browser != "Firefox") {
    //   console.log("test")
    //   this.$router.replace({ name: "NotSupportPage" })
    // }
  },
};

</script>

<style lang="scss">
@import "./scss/utilities.scss";
@import "./scss/app.scss";

.not-support {
  position: fixed !important;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100000000;
  &.bottom {
    top: auto;
    bottom: 0;
  }
}
</style>
