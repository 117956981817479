import serverApi from "../serverApi";

export const reportApi = {
  // async getMonthlyReport({date}) {
  //     return await serverApi.get(`/report/get-monthly-report/?date=${date}`);
  // },

  async getClientReport() {
    return await serverApi.get(`/report/get-client-report`);
  },

  async getClientLiveSessionDataReport() {
    return await serverApi.get(`/report/get-client-live-session-data-report`);
  },

  async getClientNoTherapistReport() {
    return await serverApi.get(`/report/get-client-no-therapist-report`);
  },

  async getTherapistAllTimeReport() {
    return await serverApi.get(`/report/get-therapist-all-time-report`);
  },

  async getClientStatusReport() {
    return await serverApi.get(`/report/get-client-status-report`);
  },


  async getReportTherapistSessionCountMonthly() {
    return await serverApi.get(
      `/report/get-therapist-client-live-sessions-monthly`
    );
  },

  async getClientInitialAssessmentReport() {
    return await serverApi.get(
      `/report/get-client-initial-assessment-report`
    );
  },

  async getUserHistory() {
    return await serverApi.get(`/report/user-history`);
  },

  async getCompanyClientActivityReport() {
    return await serverApi.get(`/report/get-company-client-activity-report`);
  },
  
  async getCompanySessionsActivityReport() {
    return await serverApi.get(`/report/get-company-sessions-activity-report`);
  },
};
