import { subscriptionApi } from "@/api";

function initialState() {
  return {
    subscriptions: [],
  };
}

export default {
  namespaced: true,

  state: initialState,

  getters: {
    subscriptions(state) {
      return state.subscriptions;
    },
  },

  mutations: {
    setSubscriptions(state, payload) {
      state.subscriptions = payload;
    },

    reset(state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },
  actions: {
    async getSubscriptions({ commit }) {
      return subscriptionApi
        .getPrices()
        .then((res) => {
          commit("setSubscriptions", res.data);
        })
        .catch((err) => {
          throw err;
        });
    },

    async subscribeAction(context, subscribeData) {
      return subscriptionApi
        .subscribe(subscribeData)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async cancelSubscriptionEndOfPeriod() {
      return subscriptionApi
        .cancelSubscriptionEndOfPeriod()
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async createLiveSession(context, numberOfLiveSessions) {
      return subscriptionApi
        .createLiveSession(numberOfLiveSessions)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },
  },

  modules: {},
};
