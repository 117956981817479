import serverApi from "../serverApi";

export const journalApi = {
  async getMyJournal() {
    return await serverApi.get("/journal");
  },

  async getUserJournal({ id }) {
    return await serverApi.get(`/journal/user/${id}`);
  },

  async createJournalMessage({ createData }) {
    return await serverApi.post("/journal", createData);
  },

  async updateJournalMessage({ id, updateData }) {
    return await serverApi.patch(`/journal/${id}`, updateData);
  },

  async removeJournalMessage({ id }) {
    return await serverApi.delete(`/journal/${id}`);
  },

  async setShared({ id, setSharedData }) {
    return await serverApi.patch(`/journal/set-shared/${id}`, setSharedData);
  },
};
