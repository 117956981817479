import { therapistApi } from "@/api";

function initialState() {
  return {
    demoTherapist: null,
  };
}

export default {
  namespaced: true,

  state: initialState,

  getters: {
    demoTherapist(state) {
      return state.demoTherapist;
    },
  },

  mutations: {
    setDemoTherapist(state, payload) {
      state.demoTherapist = payload;
    },

    reset(state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },
  actions: {
    async getAllTherapists() {
      return therapistApi
        .getAllTherapists()
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        })
        .finally(() => {});
    },

    async getTherapistForSelection() {
      return therapistApi
        .getTherapistForSelection()
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        })
        .finally(() => {});
    },

    async changeTherapistForUser(context, changeTherapistData) {
      return therapistApi
        .changeTherapistForUser(changeTherapistData)
        .then((res) => {
          // dispatch('user/getMe', null, {root: true})
          return res.data;
        })
        .catch((err) => {
          throw err;
        })
        .finally(() => {});
    },

    async createTherapist(context, createTherapistData) {
      return therapistApi
        .createTherapist(createTherapistData)
        .then((res) => {
          // dispatch('user/getMe', null, {root: true})
          return res.data;
        })
        .catch((err) => {
          throw err;
        })
        .finally(() => {});
    },

    async addTherapistToUserCompany(context, changeTherapistData) {
      return therapistApi
        .addTherapistToUserCompany(changeTherapistData)
        .then((res) => {
          // dispatch('user/getMe', null, {root: true})
          return res.data;
        })
        .catch((err) => {
          throw err;
        })
        .finally(() => {});
    },
  },

  modules: {},
};
