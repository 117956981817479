/* eslint-disable */

const {
  connect,
  createLocalTracks,
  createLocalAudioTrack,
  createLocalVideoTrack,
  isSupported,
} = require("twilio-video");

export default class TwilioVideoStream {
  constructor() {
    this.localAudioTrack = null;
    this.localVideoTrack = null;
    // this.client = null;
    this.token = null;
    this.room = null;
    this.participants = null;
    this._updated = false;
    // this.remoteUserPublished = null;

    // this.options = {
    // Pass your App ID here.
    // appId: "687e495858304ef1864190ab090e60f1",
    // Set the channel name.
    // channel: "test",
    // Pass your temp token here.
    // token: "0060a978ad0cefb473dbceb22f692cf8d6cIACo81gCYhWrBB9Rm9bcIDs85nuAJiX8Qubj3Yav0oCKdQx+f9gAAAAAEAAJmhJvmYNDYgEAAQCZg0Ni",
    // Set the user ID.
    // uid: 123456
    // };

    // this.videoStream = null;
    // this.myVideo = null;
    // this.myAudio = null;
    // this.videoEnabled = false;
    // this.audioEnabled = false;
  }

  toggleMyVideoActive(value = null) {
    if (!this.localVideoTrack) return;

    if (this.videoEnabled) {
      this.localVideoTrack.disable();
    } else {
      this.localVideoTrack.enable();
    }

    // this.myVideo.enabled = value ?? !this.myVideo.enabled;
    // this.videoEnabled = this.myVideo.enabled;
  }

  toggleMyAudioActive(value = null) {
    if (!this.localAudioTrack) return;
    if (this.audioEnabled) {
      this.localAudioTrack.disable();
    } else {
      this.localAudioTrack.enable();
    }
  }

  get videoEnabled() {
    if (!this.localVideoTrack) return false;
    return this.localVideoTrack.isEnabled;
  }

  get audioEnabled() {
    if (!this.localAudioTrack) return false;
    return this.localAudioTrack.isEnabled;
  }

  checkSystemRequirements() {
    return isSupported;
  }

  async publishStream(withTracks = true) {
    if (this.room) {
      await this.room.disconnect();
    }

    console.log("this.token: ", this.token);
    let tracks = [];

    if (withTracks) {
      await this.getOrCreateTracks();
      tracks = [this.localAudioTrack, this.localVideoTrack];
    }

    this.room = await connect(this.token, {
      tracks,
      networkQuality: {
        local: 3,
      },
    });

    this.room.localParticipant.setNetworkQualityConfiguration({
      local: 3,
    });

    console.log("this.room: ", this.room);

    this.participants = this.room.participants;
    this._updated = !this._updated;
    // When a Participant joins the Room, log the event.
    this.room.on("participantConnected", (participant) => {
      console.log("participant: ", participant);
      this.participants = this.room?.participants;
      this._updated = !this._updated;

      participant.on("trackSubscribed", (track) => {
        console.log("---trackSubscribed: track:", track);
      });

      participant.on("trackPublished", function (track) {
        console.log("---trackPublished: track:", track);
      });

      participant.on("trackUnpublished", (track) => {
        console.log("---trackUnpublished: track:", track);
      });

      participant.on("trackStarted", (track) => {
        console.log("---trackStarted: track: ", track);
      });
    });

    // When a Participant adds a Track, attach it to the DOM.
    // this.room.on('trackAdded', function(track, participant) {
    //     console.log('trackAdded: ', track, "participant: ", participant)
    // });

    // When a Participant removes a Track, detach it from the DOM.
    // this.room.on('trackRemoved', function(track, participant) {
    //     console.log('trackRemoved: ', track, "participant: ", participant)
    // });

    // When a Participant leaves the Room, detach its Tracks.
    this.room.on("participantDisconnected", (participant) => {
      console.log("participantDisconnected: ", participant);
      this.participants = this.room?.participants;
      this._updated = !this._updated;
    });

    this.room.on("participantReconnected", (participant) => {
      console.log("participantDisconnected: ", participant);
      this.participants = this.room?.participants;
      this._updated = !this._updated;
    });

    // Once the LocalParticipant leaves the room, detach the Tracks
    // of all Participants, including that of the LocalParticipant.
    this.room.on("disconnected", () => {
      console.log("disconnected");
      this._updated = !this._updated;
      this.leaveChanel();
    });

    // await this.client.publish([this.localAudioTrack, this.localVideoTrack]);
  }

  async getCameras() {
    const deviceInfos = await navigator.mediaDevices.enumerateDevices();
    return deviceInfos.filter(function (deviceInfo) {
      return deviceInfo.kind === "videoinput";
    });
    // return await AgoraRTC.getCameras()
  }

  async getMicrophones() {
    const deviceInfos = await navigator.mediaDevices.enumerateDevices();
    return deviceInfos.filter(function (deviceInfo) {
      return deviceInfo.kind === "audioinput";
    });

    // return await AgoraRTC.getMicrophones()
  }

  async setCamera(deviceId) {
    let detachParent;
    let oldLocalVideoTrack = this.localVideoTrack;

    oldLocalVideoTrack.detach().forEach(function (detachedElement) {
      detachParent = detachedElement.parentElement;
      detachedElement.remove();
    });

    this.localVideoTrack = await createLocalVideoTrack({
      deviceId: { exact: deviceId },
    });

    detachParent?.appendChild(this.localVideoTrack.attach());

    if (this.room) {
      this.room.localParticipant.unpublishTrack(oldLocalVideoTrack);
      await this.room.localParticipant.publishTrack(this.localVideoTrack);
    }

    // .then(function(localVideoTrack) {
    // const tracks = Array.from(localParticipant.videoTracks.values());
    // localParticipant.unpublishTracks(tracks);
    // log(localParticipant.identity   " removed track: "   tracks[0].kind);
    // detachTracks(tracks);
    //
    // localParticipant.publishTrack(localVideoTrack);
    // log(localParticipant.identity   " added track: "   localVideoTrack.kind);
    // const previewContainer = document.getElementById('local-media');
    // attachTracks([localVideoTrack], previewContainer);
    // });
    // await this.localVideoTrack.setDevice(deviceId)
  }

  async setMicrophone(deviceId) {
    let detachParent;
    let oldLocalAudioTrack = this.localAudioTrack;

    this.localAudioTrack.detach().forEach(function (detachedElement) {
      detachParent = detachedElement.parentElement;
      detachedElement.remove();
    });

    this.localAudioTrack = await createLocalAudioTrack({
      deviceId: { exact: deviceId },
    });

    detachParent?.appendChild(this.localAudioTrack.attach());

    if (this.room) {
      this.room.localParticipant.unpublishTrack(oldLocalAudioTrack);
      await this.room.localParticipant.publishTrack(this.localAudioTrack);
    }
  }

  async getOrCreateTracks() {
    if (!this.localAudioTrack || !this.localVideoTrack) {
      const microphoneTrack = await createLocalAudioTrack();
      const cameraTrack = await createLocalVideoTrack();
      // const [microphoneTrack, cameraTrack] = await AgoraRTC.createMicrophoneAndCameraTracks();

      console.log("getOrCreateTracks: ");
      console.log("microphoneTrack: ", microphoneTrack);
      console.log("cameraTrack: ", cameraTrack);

      this.localAudioTrack = microphoneTrack;
      this.localVideoTrack = cameraTrack;

      // AgoraRTC.onMicrophoneChanged = async (changedDevice) => {
      //     try {
      //         // When plugging in a device, switch to a device that is newly plugged in.
      //         if (changedDevice.state === "ACTIVE") {
      //             await this.localAudioTrack.setDevice(changedDevice.device.deviceId);
      //             // Switch to an existing device when the current device is unplugged.
      //         } else if (changedDevice.device.label === this.localAudioTrack.getTrackLabel()) {
      //             const oldMicrophones = await AgoraRTC.getMicrophones();
      //             oldMicrophones[0] && await this.localAudioTrack.setDevice(oldMicrophones[0].deviceId);
      //         }
      //     } catch (e) {
      //         console.log("AgoraRTC.onMicrophoneChanged: Error: ", e);
      //     }
      // }
      //
      // AgoraRTC.onCameraChanged = async (changedDevice) => {
      //     try {
      //         // When plugging in a device, switch to a device that is newly plugged in.
      //         if (changedDevice.state === "ACTIVE") {
      //             this.localVideoTrack.setDevice(changedDevice.device.deviceId);
      //             // Switch to an existing device when the current device is unplugged.
      //         } else if (changedDevice.device.label === this.localVideoTrack.getTrackLabel()) {
      //             const oldCameras = await AgoraRTC.getCameras();
      //             oldCameras[0] && await this.localVideoTrack.setDevice(oldCameras[0].deviceId);
      //         }
      //     } catch (e) {
      //         console.log("AgoraRTC.onCameraChanged: Error: ", e);
      //     }
      // }
    }
  }

  // async addPublishEvent() {
  //     this.client.on("user-published", async (user, mediaType) => {
  //         await this.client.subscribe(user, mediaType);
  //         this.remoteUserPublished = true;
  //
  //         // this.client.on("user-unpublished", (/*user*/) => {
  //         //     console.log("user-unpublished: ".repeat(100));
  //         //     this.remoteUserPublished = false;
  //         // });
  //
  //         this.client.on('user-left', (/*user*/) => {
  //             this.remoteUserPublished = false;
  //             // console.log("user-left -- : ".repeat(5), user);
  //         })
  //     })
  // }

  // async createClientChanel({channel, userId}) {
  //     if (!this.client) this.client = AgoraRTC.createClient({mode: "rtc", codec: "vp8"});
  //
  //     await this.client.join(this.options.appId, channel, this.token, userId);
  //
  //     await this.addPublishEvent()
  // }

  async leaveChanel() {
    // this.remoteUserPublished = null;

    // this.token = null;

    await this.removeVideoStream();

    if (this.room) {
      await this.room.disconnect();
    }
    // if (this.client) {
    //     await this.client.leave();
    //     await this.client.removeAllListeners();
    //     this.client = null;
    // }
  }

  // async startBasicCall() {
  //
  //     // Listen for the "user-published" event, from which you can get an AgoraRTCRemoteUser object.
  //     this.client.on("user-published", async (user, mediaType) => {
  //         // Subscribe to the remote user when the SDK triggers the "user-published" event
  //         await this.client.subscribe(user, mediaType);
  //
  //         // If the remote user publishes a video track.
  //         if (mediaType === "video") {
  //             // Get the RemoteVideoTrack object in the AgoraRTCRemoteUser object.
  //             const remoteVideoTrack = user.videoTrack;
  //             // Dynamically create a container in the form of a DIV element for playing the remote video track.
  //             const remotePlayerContainer = document.createElement("div");
  //             // Specify the ID of the DIV container. You can use the uid of the remote user.
  //             remotePlayerContainer.id = user.uid.toString();
  //             remotePlayerContainer.textContent = "Remote user " + user.uid.toString();
  //             remotePlayerContainer.style.width = "640px";
  //             remotePlayerContainer.style.height = "480px";
  //
  //             this.$refs.vidPlace.append(remotePlayerContainer);
  //
  //             // Play the remote video track.
  //             // Pass the DIV container and the SDK dynamically creates a player in the container for playing the remote video track.
  //             remoteVideoTrack.play(remotePlayerContainer);
  //
  //             // Or just pass the ID of the DIV container.
  //             // remoteVideoTrack.play(playerContainer.id);
  //         }
  //
  //         // If the remote user publishes an audio track.
  //         if (mediaType === "audio") {
  //             // Get the RemoteAudioTrack object in the AgoraRTCRemoteUser object.
  //             const remoteAudioTrack = user.audioTrack;
  //             // Play the remote audio track. No need to pass any DOM element.
  //             remoteAudioTrack.play();
  //         }
  //
  //         // Listen for the "user-unpublished" event
  //         this.client.on("user-unpublished", user => {
  //             // Get the dynamically created DIV container.
  //             const remotePlayerContainer = document.getElementById(user.uid);
  //             // Destroy the container.
  //             remotePlayerContainer.remove();
  //         });
  //
  //     });
  // }

  removeVideoStream() {
    this.localAudioTrack?.detach();
    this.localAudioTrack?.stop();
    this.localVideoTrack?.detach();
    this.localVideoTrack?.stop();

    this.localAudioTrack = null;
    this.localVideoTrack = null;
    this.room = null;
    this.participants = null;
    this._updated = false;

    // this.myVideo.stop();
    // this.myAudio.stop();
    //
    // this.videoStream = null;
    // this.myVideo = null;
    // this.myAudio = null;
    // this.videoEnabled = false;
    // this.audioEnabled = false;
  }

  install(Vue) {
    // let instance = this;

    // Vue.mixin({

    // data: () => {
    //     return {
    //         asdsad: instance.videoEnabled
    //     }
    // },
    // computed: {

    // a12() {
    //     return instance.videoEnabled
    // }
    //     videoStream() {
    //         return this.$videoStream.videoStream
    //     },
    //     myVideo() {
    //         return  this.$videoStream.myVideo
    //     },
    //     myAudio() {
    //         return  this.$videoStream.myAudio
    //     },
    //     videoEnabled() {
    //         return  this.$videoStream.videoEnabled
    //     },
    //     audioEnabled() {
    //         return  this.$videoStream.audioEnabled
    //     },
    // },
    //
    // methods: {
    //     toggleMyVideoActive() {
    //         this.$videoStream.toggleMyVideoActive();
    //     },
    //
    //     toggleMyAudioActive() {
    //         this.$videoStream.toggleMyAudioActive();
    //     }
    // }
    // })
    window.TwilioVideoStream = this;
    Vue.prototype.$TwilioVideoStream = this;
  }
}
