import {therapistApi, userApi} from "@/api";
import moment from "moment";

function initialState() {
    return {
        accessToken: "",
        refreshToken: "",
        me: {},
        adminToken: null,
        adminRoles: [],
        subscription: null,
        clients: null,
        refreshTokenRequested: false
    };
}

export default {
    namespaced: true,

    state: initialState,

    getters: {
        isLoggedIn(state) {
            return !!state.accessToken;
        },

        me(state) {
            return state.me;
        },

        accessToken(state) {
            return state.accessToken;
        },

        adminToken(state) {
            return state.adminToken;
        },

        adminRoles(state) {
            return state.adminRoles;
        },

        subscription(state) {
            return state.subscription;
        },

        clients(state) {
            return state.clients;
        },
    },

    mutations: {
        setAccessToken(state, payload) {
            state.accessToken = payload;
        },

        setRefreshToken(state, payload) {
            state.refreshToken = payload;
        },

        setMe(state, payload) {
            state.me = payload;
        },

        setAdminToken(state, payload) {
            state.adminToken = payload;
        },

        setAdminRoles(state, payload) {
            state.adminRoles = payload;
        },

        setSubscription(state, payload) {
            state.subscription = payload;
        },

        _setFirstTimeLogin(state) {
            state.me.firstTimeLogin = false;
        },

        langChanged(state, payload) {
            state.me.lang = payload;
        },

        setClients(state, payload) {
            state.clients = payload;
        },

        reset(state) {
            const s = initialState();
            Object.keys(s).forEach((key) => {
                state[key] = s[key];
            });
        },
    },
    actions: {
        async login({commit}, loginData) {
            return userApi
                .login(loginData)
                .then((res) => {
                    commit("setAccessToken", res.data.accessToken);
                    commit("setRefreshToken", res.data.refreshToken);
                    return res.data;
                })
                .catch((err) => {
                    commit("setAccessToken", "");
                    commit("setRefreshToken", "");
                    commit(
                        "addNotification",
                        {
                            notificationType: 'loginAction',
                            message: err.response.data.message,
                            icon: "mdi-alert",
                            timeout: 1000,
                        },
                        {root: true}
                    );
                    throw err;
                });
        },

        async refreshAccessToken({commit, state}) {
            if (!state.refreshTokenRequested) {
                state.refreshTokenRequested = true;
                return userApi
                    .refreshAccessToken({refreshToken: state.refreshToken})
                    .then((res) => {
                        commit("setAccessToken", res.data.accessToken);
                        commit("setRefreshToken", res.data.refreshToken);
                        return res.data;
                    })
                    .catch((err) => {
                        commit("setAccessToken", "");
                        commit("setRefreshToken", "");
                        commit(
                            "addNotification",
                            {
                                notificationType: 'loginAction',
                                message: err.response.data.message,
                                icon: "mdi-alert",
                                timeout: 1000,
                            },
                            {root: true}
                        );
                        throw err;
                    }).finally(() => {
                        state.refreshTokenRequested = false;
                    });
            }
        },

        async register({commit}, registerData) {
            return userApi
                .register(registerData)
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    commit(
                        "addNotification",
                        {
                            message: err.response.data.message,
                            icon: "mdi-alert",
                            timeout: 30000,
                        },
                        {root: true}
                    );
                    throw err;
                });
        },

        async resendVerification({commit}, resendData) {
            return userApi
                .resendVerification(resendData)
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    commit(
                        "addNotification",
                        {message: err.response.data.message, icon: "mdi-alert"},
                        {root: true}
                    );
                    throw err;
                });
        },

        async verify({commit}, verificationData) {
            return userApi
                .verify(verificationData)
                .then((res) => {
                    commit("setAccessToken", res.data.accessToken);
                    commit("setRefreshToken", res.data.refreshToken);
                    return res.data;
                })
                .catch((err) => {
                    commit(
                        "addNotification",
                        {
                            message: err.response.data.message,
                            icon: "mdi-alert",
                            timeout: 30000,
                        },
                        {root: true}
                    );
                    throw err;
                });
        },

        async forgotPassword({commit}, forgotPasswordData) {
            return userApi
                .forgotPassword(forgotPasswordData)
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    commit(
                        "addNotification",
                        {
                            message: err.response.data.message,
                            icon: "mdi-alert",
                            timeout: 30000,
                        },
                        {root: true}
                    );
                    throw err;
                });
        },

        async forgotPasswordVerify(context, verificationData) {
            return userApi
                .forgotPasswordVerify(verificationData)
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    // commit(
                    //     'addNotification',
                    //     {
                    //         message: err.response.data.message,
                    //         icon: 'mdi-alert',
                    //         timeout: 3000,
                    //     },
                    //     {root: true},
                    // );
                    throw err;
                });
        },

        async forgotPasswordReset({commit}, resetPasswordData) {
            return userApi
                .forgotPasswordReset(resetPasswordData)
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    commit(
                        "addNotification",
                        {
                            message: err.response.data.message,
                            icon: "mdi-alert",
                            timeout: 3000,
                        },
                        {root: true}
                    );
                    throw err;
                });
        },

        logout({dispatch}) {
            dispatch("clearAllState", null, {root: true});
        },

        async getMe({dispatch, commit, getters, rootGetters}) {
            commit("addAwaitPromise", {key: "getMe"}, {root: true});
            commit("setGlobalLoading", true, {root: true});
            return userApi
                .getMe()
                .then((res) => {
                    commit("setMe", res.data.user);
                    commit("setSubscription", res.data.subscription);

                    if (res.data?.user?.roles?.includes("admin")) {
                        commit("setAdminToken", getters.accessToken);

                        commit("setAdminRoles", res.data?.user?.roles);
                    }

                    if (!getters.adminToken) {
                        if (getters.me?.lang !== rootGetters.lang) {
                            if (["en", "ar"].includes(rootGetters.lang)) {
                                dispatch("updateMeLang", {lang: rootGetters.lang});
                            }
                        }

                        let currentOffset = moment().utcOffset();
                        if (getters.me?.utcOffset !== currentOffset) {
                            dispatch("updateMeUtcOffset", {utcOffset: currentOffset});
                        }
                    }
                })
                .catch((err) => {
                    throw err;
                })
                .finally(() => {
                    commit("doneAwaitPromise", {key: "getMe"}, {root: true});
                    commit("setGlobalLoading", false, {root: true});
                });
        },

        async updateMeLang({commit}, updateData) {
            return userApi
                .updateMeLang(updateData)
                .then((res) => {
                    commit("langChanged", res.data.updated.lang);
                })
                .catch((err) => {
                    throw err;
                });
        },

        async updateMeUtcOffset({commit}, updateData) {
            return userApi
                .updateMeUtcOffset(updateData)
                .then((res) => {
                    commit("langChanged", res.data.updated.lang);
                })
                .catch((err) => {
                    throw err;
                });
        },

        async updateMe({dispatch}, updateData) {
            return userApi
                .updateMe(updateData)
                .then(() => {
                    dispatch("getMe");
                })
                .catch((err) => {
                    throw err;
                });
        },

        async updateUserBriefAssessment({dispatch}, updateData) {
            return userApi
                .updateUserBriefAssessment(updateData)
                .then(() => {
                    dispatch("getMe");
                })
                .catch((err) => {
                    throw err;
                });
        },

        async changePassword({commit}, changePasswordData) {
            return userApi
                .changePassword(changePasswordData)
                .then((res) => {
                    return res;
                })
                .catch((err) => {
                    commit(
                        "addNotification",
                        {
                            message: err.response.data.message,
                            icon: "mdi-alert",
                            timeout: 30000,
                        },
                        {root: true}
                    );
                    throw err;
                });
        },

        async updateInitialAssessment({dispatch}, updateData) {
            return userApi
                .updateInitialAssessment(updateData)
                .then((res) => {
                    dispatch("getMe");
                    return res;
                })
                .catch((err) => {
                    throw err;
                });
        },

        async setFirstTimeLogin({commit}) {
            return userApi
                .setFirstTimeLogin()
                .then(() => {
                    commit("_setFirstTimeLogin");
                })
                .catch((err) => {
                    throw err;
                });
        },

        async getClientsAndSubscriptions({commit}, {id = ""} = {}) {
            return therapistApi
                .getClientsAndSubscriptions({id})
                .then((res) => {
                    commit("setClients", res.data);
                    return res.data;
                })
                .catch((err) => {
                    throw err;
                });
        },

        // async getClientsExport() {
        //     return userApi
        //         .getClientsExport()
        //         .then((res) => {
        //             return res.data
        //         })
        //         .catch((err) => {
        //             throw err;
        //         })
        // },

        async getTherapistsForAdminTherapistPanel() {
            return userApi
                .getTherapistsForAdminTherapistPanel()
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    throw err;
                });
        },

        async updateTherapistsForAdminTherapistPanel(context, {id, data}) {
            return userApi
                .updateTherapistsForAdminTherapistPanel({id, data})
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    throw err;
                });
        },

        async getTherapistsForAdmin() {
            return userApi
                .getTherapistsForAdmin()
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    throw err;
                });
        },

        async getClientsForAdmin() {
            return userApi
                .getClientsForAdmin()
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    throw err;
                });
        },

        async getClientsLogForAdmin() {
            return userApi
                .getClientsLogForAdmin()
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    throw err;
                });
        },

        async getClientsLogForAdminV2(_context, query) {
            return userApi
                .getClientsLogForAdminV2(query)
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    throw err;
                });
        },

        async updateClientsLiseSessionForAdmin(context, {clientId, data}) {
            return userApi
                .updateClientsLiseSessionForAdmin({clientId, data})
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    throw err;
                });
        },

        async updateClientStatusForAdmin(context, {clientId, data}) {
            return userApi
                .updateClientStatusForAdmin({clientId, data})
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    throw err;
                });
        },

        async loginForAdmin({commit}, data) {
            return userApi
                .loginForAdmin(data)
                .then((res) => {
                    commit("setAccessToken", res.data.accessToken);
                    commit("setRefreshToken", "");
                    return res.data;
                })
                .catch((err) => {
                    throw err;
                });
        },

        async addPromoCodeToUser(context, data) {
            return userApi
                .addPromoCodeToUser(data)
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    throw err;
                });
        },

        async removePromoCodeFromUser(context, data) {
            return userApi
                .removePromoCodeFromUser(data)
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    throw err;
                });
        },


        async getCompanyAndInsuranceUsers() {
            return userApi
                .getCompanyAndInsuranceUsers()
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    throw err;
                });
        },

        async updateCompanyAndInsuranceUser(context, {id, data}) {
            return userApi
                .updateCompanyAndInsuranceUser({id, data})
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    throw err;
                });
        },

        async updateInsuranceUserData(context, {data}) {
            return userApi
                .updateInsuranceUserData({data})
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    throw err;
                });
        },
    },

    modules: {},
};
