import { corporatePlanApi } from "@/api";

function initialState() {
  return {};
}

export default {
  namespaced: true,

  state: initialState,

  getters: {},

  mutations: {
    // reset(state) {
    //     const s = initialState();
    //     Object.keys(s).forEach((key) => {
    //         state[key] = s[key];
    //     });
    // },
  },
  actions: {
    async getCorporatePlans() {
      return corporatePlanApi
        .getCorporatePlans()
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async getCorporatePlan(context, { id }) {
      return corporatePlanApi
        .getCorporatePlan({ id })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async createCorporatePlan(context, { createData }) {
      return corporatePlanApi
        .createCorporatePlan(createData)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    async updateCorporatePlan(context, { id, updateData }) {
      return corporatePlanApi
        .updateCorporatePlan({ id, updateData })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },
  },

  modules: {},
};
