import Vue from "vue";
import Vuex from "vuex";

import plugins from "@/store/plugins";
import preRegister from "@/store/modules/preRegister";
import app from "@/store/modules/app";

import user from "@/store/modules/user";
import therapist from "@/store/modules/therapist";
import disorder from "@/store/modules/disorder";
import subscription from "@/store/modules/subscription";
import calendar from "@/store/modules/calendar";
import journal from "@/store/modules/journal";
import clientFile from "@/store/modules/clientFile";
import report from "@/store/modules/report";
import clientSurvey from "@/store/modules/clientSurvey";
import corporateProgram from "@/store/modules/corporateProgram";
import corporatePlan from "@/store/modules/corporatePlan";
import therapistTier from "@/store/modules/therapistTier"

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    awaitPromise: [],
  },
  getters: {},
  mutations: {
    addAwaitPromise(state, { key }) {
      let current = state.awaitPromise.find((e) => e.key === key);
      if (!current) {
        state.awaitPromise.push({
          key,
          callbacks: [],
        });
      }
    },

    doneAwaitPromise(state, { key }) {
      let index = state.awaitPromise.findIndex((e) => e.key === key);
      if (index !== -1) {
        let current = state.awaitPromise[index];
        current?.callbacks.forEach((cb) => {
          if (typeof cb === "function") {
            cb();
          }
        });
        state.awaitPromise.splice(index, 1);
      }
    },

    awaitPromiseCallback(state, { key, cb }) {
      let current = state.awaitPromise.find((e) => e.key === key);
      if (!current) {
        cb();
      } else {
        current.callbacks.push(cb);
      }
    },
  },
  actions: {
    clearAllState({ state, commit }) {
      state.awaitPromise = [];
      commit("reset");
      commit("user/reset");
    },
  },
  modules: {
    app,
    preRegister,

    user,
    therapist,
    disorder,
    subscription,
    calendar,
    journal,
    clientFile,
    report,
    clientSurvey,
    corporateProgram,
    corporatePlan,
    therapistTier,
  },

  plugins,
});
