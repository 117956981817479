import { reportApi } from "@/api";

export default {
  namespaced: true,

  actions: {
    // async getMonthlyReport(context, {date}) {
    //   return reportApi
    //     .getMonthlyReport({date})
    //     .then((res) => {
    //         return res.data
    //     })
    //     .catch((err) => {
    //       throw err;
    //     })
    //     .finally(() => {});
    // },

    async getClientReport() {
      return reportApi
        .getClientReport()
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        })
        .finally(() => {});
    },

    async getClientLiveSessionDataReport() {
      return reportApi
        .getClientLiveSessionDataReport()
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        })
        .finally(() => {});
    },

    async getClientNoTherapistReport() {
      return reportApi
        .getClientNoTherapistReport()
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        })
        .finally(() => {});
    },

    async getTherapistAllTimeReport() {
      return reportApi
        .getTherapistAllTimeReport()
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        })
        .finally(() => {});
    },

    async getClientStatusReport() {
      return reportApi
        .getClientStatusReport()
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        })
        .finally(() => {});
    },

    async getReportTherapistSessionCountMonthly() {
      return reportApi
        .getReportTherapistSessionCountMonthly()
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        })
        .finally(() => {});
    },

    async getClientInitialAssessmentReport() {
      return reportApi
        .getClientInitialAssessmentReport()
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        })
        .finally(() => {});
    },

    async getUserHistory() {
      return reportApi
        .getUserHistory()
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        })
        .finally(() => {});
    },

    async getCompanyClientActivityReport() {
      return reportApi
        .getCompanyClientActivityReport()
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        })
        .finally(() => {});
    },
    
    async getCompanySessionsActivityReport() {
      return reportApi
        .getCompanySessionsActivityReport()
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err;
        })
        .finally(() => {});
    },
  },
};
