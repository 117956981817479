import serverApi from "../serverApi";

export const userApi = {
  async getMe() {
    return await serverApi.get("/user/me");
  },

  async updateMe(updateData) {
    return await serverApi.patch("/user/me", updateData);
  },

  async updateUserBriefAssessment(updateData) {
    return await serverApi.patch("/user/me-brief-assessment", updateData);
  },

  async updateMeLang(updateData) {
    return await serverApi.patch("/user/me-lang", updateData);
  },

  async updateMeUtcOffset(updateData) {
    return await serverApi.patch("/user/utc-offset", updateData);
  },

  async updateInitialAssessment(updateData) {
    return await serverApi.patch("/user/me/initial-assessment", updateData);
  },

  async changePassword(changePasswordData) {
    return await serverApi.patch(
      "/user/me/change-password",
      changePasswordData
    );
  },

  async login(loginData) {
    return await serverApi.post("/user/login", loginData);
  },

  async refreshAccessToken(refreshTokenData) {
    return await serverApi.post("/user/refresh-access-token", refreshTokenData);
  },

  async register(registerData) {
    return await serverApi.post("/user/register", registerData);
  },

  async resendVerification(resendData) {
    return await serverApi.post("/user/resend-verification", resendData);
  },

  async verify(verificationData) {
    return await serverApi.post("/user/verify-email/", verificationData);
  },

  async forgotPassword(forgotPasswordData) {
    return await serverApi.post("/user/forgot-password/", forgotPasswordData);
  },

  async forgotPasswordVerify(verificationData) {
    return await serverApi.post(
      "/user/forgot-password-verify/",
      verificationData
    );
  },

  async forgotPasswordReset(resetPasswordData) {
    return await serverApi.post("/user/reset-password/", resetPasswordData);
  },

  async setFirstTimeLogin() {
    return await serverApi.post("/user/first-time-login");
  },

  // async getClientsExport() {
  //   return await serverApi.get(
  //       '/user/export-clients'
  //   );
  // },

  async getTherapistsForAdminTherapistPanel() {
    return await serverApi.get("/user/therapists-for-admin-therapist-panel");
  },

  async updateTherapistsForAdminTherapistPanel({ id, data }) {
    return await serverApi.patch("/user/therapists-for-admin-therapist-panel/" + id, data);
  },

  async getTherapistsForAdmin() {
    return await serverApi.get("/user/therapists-for-admin");
  },

  async getClientsForAdmin() {
    return await serverApi.get("/user/clients-for-admin");
  },

  async getClientsLogForAdmin() {
    return await serverApi.get("/user/clients-log-for-admin");
  },

  async getClientsLogForAdminV2(query) {
    return await serverApi.get(`/v2/user/clients-info-for-admin?${query}`);
  },


  async getUsersMany(dto) {
    return await serverApi.get("/user", {
      params: dto,
    });
  },

  async getUserFilteringTags(dto) {
    return await serverApi.get("/user/meta/tags", {
      params: dto,
    });
  },

  async updateClientsLiseSessionForAdmin({ clientId, data }) {
    return await serverApi.patch(
      "/user/clients-live-session-for-admin/" + clientId,
      data
    );
  },

  async updateClientStatusForAdmin({ clientId, data }) {
    return await serverApi.patch(
      "/v2/user/clients-status-for-admin/" + clientId,
      data
    );
  },

  async loginForAdmin(data) {
    return await serverApi.post("/user/login-for-admin", data);
  },

  async addPromoCodeToUser(data) {
    return await serverApi.post("/user/add-promo-code-to-user", data);
  },

  async removePromoCodeFromUser(data) {
    return await serverApi.post("/user/remove-promo-code", data);
  },

  async getCompanyAndInsuranceUsers() {
    return await serverApi.get("/user/company-and-insurance");
  },


  async updateCompanyAndInsuranceUser({id, data}) {
    return await serverApi.patch(`/user/${id}/company-and-insurance`, data);
  },

  async updateInsuranceUserData({data}) {
    return await serverApi.patch(`/user/change-company-and-insurance`, data);
  },
};

export { therapistApi } from "./therapist";
